<template>
  <div ref="componentRef" class="language-settings relative">
    <button
      class="group relative h-24 w-24 text-on-surface-elevation-1 gnb-dropdown"
      type="button"
      @click="toggleShow"
    >
      <i
        class="stove-ui-icon h-24 w-24 text-4xl before:ic-v2-community-public-line group-hover:hidden group-active:hidden"
      ></i>
      <i
        class="stove-ui-icon h-24 w-24 text-4xl before:ic-v2-community-public-fill hidden group-hover:inline-flex group-active:inline-flex"
      ></i>
      <span class="language-tooltip gnb-tooltip">{{ hoverText }}</span>
    </button>

    <div
      class="gnb-dropdown-content absolute z-[600] inset-auto m-0 transform translate-y-8"
      :class="{ invisible: !show, visible: show }"
    >
      <div style="display: inherit">
        <div class="z-dropdown">
          <div class="language-contents stds-contents-popup-container" style="min-width: 200px">
            <div class="max-h-[calc(100vh-6.4rem)] py-[2.4rem] px-[1.6rem] gap-10">
              <div class="language-ttl flex items-center gap-[.6rem]">
                <i
                  class="stove-ui-icon h-20 w-20 text-3xl before:ic-v2-community-public-line text-on-surface-elevation-1"
                ></i>
                <p class="text-xl leading-lg font-bold text-on-surface-elevation-1">
                  {{ headerText }}
                </p>
              </div>
              <div class="mt-[.6rem] flex flex-col gap-8">
                <div
                  v-for="lang in languages"
                  :key="lang.id"
                  class="relative w-full"
                  @click.prevent="changeLang(lang.id)"
                >
                  <input
                    :id="`language-${lang.id}`"
                    type="radio"
                    name="language-setting"
                    class="peer absolute top-0 left-0 opacity-0"
                    :checked="lang.id === selectedLang"
                  />
                  <label
                    :for="`language-${lang.id}`"
                    class="language-text flex h-32 cursor-pointer items-center rounded-full px-12 text-xs font-medium leading-sm text-on-surface-elevation-2 hover:bg-interaction-hover peer-checked:bg-neutral-variant-1 peer-checked:font-bold peer-checked:text-background-variant-2"
                  >{{ lang.label }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';

const props = defineProps<{
  languages: { id: string; label: string }[];
  currentLang: string;
  headerText: string;
  hoverText: string;
  onChangeLang:(lang: string) => void;
}>();

const show = ref(false);
const selectedLang = ref(props.currentLang);
const componentRef = ref<HTMLElement | null>(null);

const toggleShow = () => {
  show.value = !show.value;
};

const changeLang = (lang: string) => {
  selectedLang.value = lang;
  props.onChangeLang(lang);
  show.value = false;
};

const handleClickOutside = (event: MouseEvent) => {
  if (
    componentRef.value &&
    !componentRef.value.contains(event.target as Node) &&
    !(event.target as Element).classList.contains('gnb-dropdown-toggle')
  ) {
    show.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>
