<template>
  <div
    ref="groupGnb"
    :class="[
      'h-68 fixed max-w-full top-0 z-[1000]',
      isScrolled ? 'bg-[#F3F7FF]/80 backdrop-blur-[20px]' : 'bg-background-variant-1',
      customClassPublicPage
    ]"
  >
    <div class="h-full px-24 py-16 flex items-center mx-auto" :class="[contentClass]">
      <div class="flex items-center gap-8">
        <a target="_blank" :href="STORE_URL">
          <img src="@/assets/images/common/gnb_stove_logo.svg" alt="STOVE" class="h-24 block" />
        </a>
        <a class="cursor-pointer" @click="goHome">
          <img src="@/assets/images/common/gnb_studio_logo.svg" alt="STUDIO" class="h-24 block" />
        </a>
      </div>
      <div class="flex gap-16 items-center ml-auto">
        <div class="wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { createVNode, nextTick, onMounted, onUnmounted, ref, render } from 'vue';
import { useI18n } from 'vue-i18n';

import GnbLanguage from '@/components/app/gnb-language.vue';
import GnbMyInfo from '@/components/app/gnb-my-info.vue';
import { useApp } from '@/composables/useApp';
import { showConfirm } from '@/composables/useDialog';
import { DEFAULT_COVERAGES } from '@/constants/locale.const';
import {
  BRAND_PAGE_URL,
  GROUP_REGISTER_PAGE_URL,
  HOME_PAGE_URL,
  MY_INFO_PAGE_URL,
  REDIRECT_TO_GROUP_HOME
} from '@/constants/url.const';
import { LanguageCode } from '@/enums/language-code.enum';
import { useUserStore } from '@/stores/user.store';
import { getConfigs, redirectTo } from '@/utils/common.util';
import { goToLoginPage, isLogin, logout } from '@/utils/user.utils';
import { useLocalePath } from '#imports';

const props = withDefaults(
  defineProps<{
    customClassPublicPage?: string;
    contentClass?: string;
    isScrolled?: boolean;
    showNotification?: boolean;
  }>(),
  {
    customClassPublicPage: '',
    contentClass: '',
    isScrolled: false,
    showNotification: true
  }
);

const userStore = useUserStore();

const { userInfo, selectedGroupId, joinedGroups } = storeToRefs(userStore);
const { t, setLocale, locale } = useI18n();
const localePath = useLocalePath();
const app = useApp();
const { GNB_SCRIPT, STORE_URL } = getConfigs();

const goHome = async () => {
  if (!isLogin()) {
    await goToLoginPage(REDIRECT_TO_GROUP_HOME);
    return;
  }
  if (joinedGroups.value?.length === 0) {
    return await redirectTo(GROUP_REGISTER_PAGE_URL);
  }
  let groupId = selectedGroupId.value;
  if (!groupId) {
    groupId = await userStore.findReadableGroupHome();
  }
  if (!groupId) {
    return await redirectTo(GROUP_REGISTER_PAGE_URL);
  }
  await redirectTo(`/${locale.value}${HOME_PAGE_URL}`, { groupId, external: true });
};

const renderGnbLanguage = (customDom: HTMLElement) => {
  const langProps = {
    languages: [
      { id: LanguageCode.Ko, label: t('studio.gnb.common_language_ko') },
      { id: LanguageCode.En, label: t('studio.gnb.common_language_us') },
      { id: LanguageCode.ZhCn, label: t('studio.gnb.common_language_zh_cn') },
      { id: LanguageCode.ZhTw, label: t('studio.gnb.common_language_zh_tw') },
      { id: LanguageCode.Ja, label: t('studio.gnb.common_language_ja') }
    ],
    currentLang: locale.value,
    onChangeLang: async (lang: string) => {
      app.changeLocale(lang, true);
    },
    hoverText: t('studio.gnb.common_language_setting'),
    headerText: t('studio.gnb.common_language_setting')
  };
  const vNode = createVNode(GnbLanguage, langProps);
  render(vNode, customDom.querySelector('.gnb-right-align')!);
};

const renderGnbMyInfo = (customDom: HTMLElement) => {
  const vNode = createVNode(GnbMyInfo, {
    myInfoText: t('studio.stu_logged_in.pre_grp_join.btn_my_info'),
    logoutText: t('studio.stu_logged_in.pre_grp_join.btn_logout'),
    nickname: userInfo.value?.nickname,
    profileImg: userInfo.value?.profileImg,
    myInfoUrl: localePath(MY_INFO_PAGE_URL),
    indexUrl: localePath(BRAND_PAGE_URL),
    onLogout: async () => {
      const result = await showConfirm({
        content: t('studio.common.popup_case_i_logout'),
        confirmLabel: t('studio.stu_logged_in.pre_grp_join.btn_logout'),
        cancelLabel: t('studio.common.popup_case_close_btn'),
        cancelVariant: 'outline'
      });
      if (result) {
        logout();
        goToLoginPage(REDIRECT_TO_GROUP_HOME);
      }
    }
  });
  render(vNode, customDom.querySelector('.gnb-dropdown-content')!);
};

const gnbOption = {
  loginMethod: {
    redirectCurrentPage: false,
    params: {
      inflow_path: 'INDIA_STUDIO',
      style_type: 'studio',
      redirect_url: `${location.origin}/${locale.value}${REDIRECT_TO_GROUP_HOME}`
    }
  },
  widget: {
    notification: props.showNotification,
    totalMenu: false,
    languageSelect: true,
    customArea: {
      template: '<div class="custom-language-area"></div>',
      eventHandler: renderGnbLanguage
    }
  },
  notice: {
    customNoticeType: [
      600, 601, 602, 603, 604, 605, 606, 607, 608, 609, 610, 611, 612, 613, 614, 615, 616, 617, 618,
      619, 620, 621, 622, 624, 625, 626, 627, 628, 629, 630, 631, 632, 633, 634
    ],
    allowFriendRequestCount: true
  },
  skin: 'app_header gnb-studio !border-b-0',
  logArea: 'studio',
  stoveLogo: {
    use: false
  },
  global: {
    languageCoverages: DEFAULT_COVERAGES,
    defaultSelectedLanguage: locale.value,
    onChangeLanguage
  },
  userMenu: {
    myCash: false,
    cartInfo: false,
    myInfo: false,
    security: false,
    customerCenter: false,
    reportCenter: false,
    logout: false,
    note: false,
    customArea: {
      eventHandler: renderGnbMyInfo
    }
  }
};

async function onChangeLanguage(locale: string) {
  await setLocale(locale);
  document.getElementById('stoveGnb')?.remove();
  new window.stove.Header(gnbOption).render();
  // location.reload();
}

// useHead({
//   script: [
//     {
//       src: GNB_SCRIPT,
//       defer: true,
//       onload: () => new window.stove.Header(gnbOption).render()
//     }
//   ]
// });

const groupGnb = ref<HTMLElement | null>(null);

onMounted(() => {
  window.addEventListener('resize', setWidth);

  nextTick(() => {
    if (groupGnb.value) {
      setWidth();
    }
    resizeAdjust();
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', setWidth);
});

const setWidth = () => {
  if (groupGnb.value) {
    const parent = groupGnb.value.parentElement;
    groupGnb.value.style.width = `${parent?.clientWidth}px`;
  }
};

const resizeAdjust = () => {
  window.addEventListener('resize', function() {
    if (!groupGnb.value) {
      return;
    }
    setWidth();
  });
};

const init = async () => {
  setTimeout(() => {
    const script = document.createElement('script');
    script.src = GNB_SCRIPT!;
    script.defer = true;
    script.onload = () => new window.cp.Header(gnbOption).render();
    document.head.appendChild(script);
  }, 100);
};

init();
</script>
