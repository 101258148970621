<template>
  <div class="mb-12 pb-12 border-b-1 border-solid border-inverse-elevation-5">
    <!-- 타임라인 링크 -->
    <a href="" class="flex min-h-48 flex-1 items-center" @click.prevent="goToMyInfoPage">
      <span class="mr-[1rem] h-40 w-40 shrink-0 overflow-hidden rounded-full">
        <img loading="lazy" :src="profileImg" alt="User Profile Image" />
      </span>
      <span
        id="gnb-nickname"
        class="break-all text-xl font-bold leading-lg text-on-surface-elevation-1 line-clamp-2"
      >
        {{ nickname }}
      </span>
    </a>
  </div>
  <div>
    <div
      class="relative rounded-lg hover:bg-interaction-hover cursor-pointer"
      @click="goToMyInfoPage"
    >
      <!-- <a class="absolute inset-0 rounded-lg active:bg-interaction-pressed" /> -->
      <div class="relative flex items-center px-8 min-h-40 gap-12">
        <div class="relative shrink-0 h-20">
          <i
            class="stove-ui-icon h-20 w-20 !text-3xl before:ic-v2-navigation-profile-line text-on-surface-elevation-1"
          ></i>
        </div>
        <div class="flex flex-1 gap-8">
          <span
            class="break-all text-md font-bold leading-sm text-on-surface-elevation-1 line-clamp-1"
          >
            {{ myInfoText }}
          </span>
        </div>
      </div>
    </div>
    <div class="relative rounded-lg hover:bg-interaction-hover cursor-pointer" @click="onLogout">
      <!-- <a class="absolute inset-0 rounded-lg active:bg-interaction-pressed" /> -->
      <div class="pointer-events-none relative flex items-center px-8 min-h-40 gap-12">
        <div class="relative shrink-0 h-20">
          <i
            class="stove-ui-icon h-20 w-20 !text-3xl before:ic-v2-navigation-logout-line text-on-surface-elevation-1"
          ></i>
        </div>
        <div class="flex flex-1 gap-8">
          <span
            class="break-all text-md font-bold leading-sm text-on-surface-elevation-1 line-clamp-1"
          >
            {{ logoutText }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue';

import { redirectTo } from '@/utils/common.util';

const props = defineProps<{
  myInfoText: string;
  logoutText: string;
  myInfoUrl: string;
  nickname: string;
  profileImg: string;
  onLogout:() => void;
}>();

const goToMyInfoPage = () => {
  redirectTo(props.myInfoUrl, { withLocale: false });
};

const handleClickOutside = (event: MouseEvent) => {
  const userWrapperElm = document.querySelector('#user-wrapper') as HTMLElement;
  if (userWrapperElm && !userWrapperElm.contains(event.target as Node)) {
    const elm = document.querySelector('#user-wrapper.is-open .gnb-dropdown-toggle') as HTMLElement;
    if (elm) {
      elm.click();
    }
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>
